var niveauxEsprit = {

	event: function () {
		$(document).on('change', "#niveaux", this.toggle);
	},

	toggle: function (e) {
		e.preventDefault();

		var val = $(this).val();

		$('.activite').hide();
		$('.activite#' + val).show();

	},

	chromeSvg: function (e) {
		$('#svgChrome').load('/Content/img/esprit-terdav/pictos-niveaux-chrome.svg', function () {
			$('.picto--5').css('fill', '#006789');

			var use2 = $('.picto--2').find('use');
			$.each(use2, function () {
				var tag = $(this).attr('xlink:href').split('#');
				if (tag[1].includes('-2') == false) {
					$(this).attr('xlink:href', '#' + tag[1] + '-2');
				}
				use2.find('path').css('fill', '#006789');
			});
			var use3 = $('.picto--3').find('use');
			$.each(use3, function () {
				var tag = $(this).attr('xlink:href').split('#');
				if (tag[1].includes('-3') == false) {
					$(this).attr('xlink:href', '#' + tag[1] + '-3');
				}
			});
			var use4 = $('.picto--4').find('use');
			$.each(use4, function () {
				var tag = $(this).attr('xlink:href').split('#');
				if (tag[1].includes('-4') == false) {
					$(this).attr('xlink:href', '#' + tag[1] + '-4');
				}
			});

		});
	},

	init: function () {
		niveauxEsprit.event();
		niveauxEsprit.chromeSvg();
	}
};