/*
*****************
ALL WEBSITE CAROUSELS
LIBRARY : SLICK.JS
*****************
*/

var carousel = {

    advisorCarousel : function() {

        if($(window).width() <= tabletWidth) {
            if(!$('.advisor__wrapper').hasClass('slick-initialized')){
                $('.advisor__wrapper').slick({
                    arrows: false,
                    infinite: false,
                    centerMode: true,
                    slidesToScroll: 1,
                    slidesToShow: 1
                });
            }
        } else {
            if($('.advisor__wrapper').hasClass('slick-initialized')){
                $('.advisor__wrapper').slick('unslick');
            }
        }

    },

	init : function(){

        $('.carousel.is-fifth').slick({
            arrows: false,
            infinite: false,
            slidesToScroll: 1,
            slidesToShow: 5,
            responsive: [
                {
                    breakpoint: desktopWidth,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: tabletWidth,
                    settings: {
                        arrows: false,
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: mobileWidth,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        slidesToShow: 1
                    }
                }
            ]
        });

        $('.carousel.is-fourth').slick({
            arrows: false,
            infinite: false,
            slidesToScroll: 1,
            slidesToShow: 4,
            responsive: [
                {
                    breakpoint: largeWidth,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: tabletWidth,
                    settings: {
                        arrows: false,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: mobileWidth,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        slidesToShow: 1
                    }
                }
            ]
        });
        
        $('.carousel.is-third').slick({
            arrows: true,
            infinite: false,
            slidesToScroll: 1,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: tabletWidth,
                    settings: {
                        arrows: false,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: mobileWidth,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        slidesToShow: 1
                    }
                }
            ]
        });

        $('.carousel.is-second').slick({
            arrows: true,
            infinite: false,
            slidesToScroll: 1,
            slidesToShow: 2,
            responsive: [
                {
                    breakpoint: desktopWidth,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: tabletWidth,
                    settings: {
                        arrows: false,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: mobileWidth,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        slidesToShow: 1
                    }
                }
            ]
        });

	}
};
