var navigationInternal = {

	event: function () {
		blockHero.on('click', ".hero-header__button", this.internalNavToggle);
	},

	internalNavToggle: function () {

		var scroll = $(window).scrollTop();
		if (($(window).width() < mobileWidth) && (scroll < 50) && !blockNavigationInternalMain.hasClass('navigation-internal_active')) {
			$(window).scrollTop(50)
		}

		blockNavigationInternalMain.toggleClass('navigation-internal_active');
		$(this).toggleClass('hero-header__button_active');
	},

	initCarouselMain: function () {
		var activeSlide = blockNavigationInternalMain.find('.navigation-internal__item_active').index();
		var slideShow = blockNavigationInternalMain.find('.navigation-internal__list').children().length - 1 > 5 ? 5 : blockNavigationInternalMain.find('.navigation-internal__list').children().length - 1;
		blockNavigationInternalMain.find('.navigation-internal__list').slick({
			arrows: true,
			infinite: true,
			slidesToScroll: 1,
			slidesToShow: slideShow,
			initialSlide: activeSlide,
			responsive: [{
				breakpoint: desktopWidth,
				settings: {
					slidesToShow: 3
				}
			}, {
				breakpoint: tabletWidth,
				settings: {
					arrows: false,
					slidesToShow: 3
				}
			}, {
				breakpoint: mobileWidth,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 1
				}
			}]
		});
	},

	initCarousel: function () {
		var activeSlide = blockNavigationInternal.find('.navigation-internal__item_active').index();
		blockNavigationInternal.find('.navigation-internal__list').slick({
			arrows: true,
			infinite: true,
			slidesToScroll: 1,
			slidesToShow: 5,
			initialSlide: activeSlide,
			responsive: [{
				breakpoint: desktopWidth,
				settings: {
					slidesToShow: 3
				}
			}, {
				breakpoint: tabletWidth,
				settings: {
					arrows: false,
					slidesToShow: 3
				}
			}, {
				breakpoint: mobileWidth,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 1
				}
			}]
		});
	},

	init: function () {
		navigationInternal.event();
		navigationInternal.initCarouselMain();
		navigationInternal.initCarousel();
	}
};