var navigation = {

	event : function() {
        pagePreparerSonVoyageSommaire.find('.navigation').on('click', ".navigation__title", this.triggerShowRubrique);
    },

	triggerShowRubrique : function(e) {
        
        if($(window).width() <= tabletWidth) {
            e.preventDefault();
        }
        
        $(this).toggleClass('navigation__title_active');

    },

	init : function(){
        navigation.event();
	}
};
