var inscription = {

	event: function () {
		$(document).on('click', "#eventInscriptionBtn", this.showInscriptionForm);
		$(document).on('click', "#eventInscriptionDescriptionBtn", this.showInscriptionDescription);
	},

	showInscriptionForm: function (e) {
		//e.preventDefault();

		$('.modal #eventInscriptionForm').css('display', 'inline-block');
		$('.modal #eventInscriptionDescription').hide();

		$('.modal #eventInscriptionDescriptionBtn').css('display', 'inline-block');
		$('.modal #eventInscriptionBtn').hide();
	},

	showInscriptionDescription: function (e) {
		//e.preventDefault();

		$('.modal #eventInscriptionForm').hide();
		$('.modal #eventInscriptionDescription').css('display', 'inline-block');

		$('.modal #eventInscriptionDescriptionBtn').hide();
		$('.modal #eventInscriptionBtn').css('display', 'inline-block');
	},

	init: function () {
		inscription.event();
	}
};