var modal = {

	event: function () {

		$('[data-open-modal="contact"]').on('click', function (e) {
			//let codeEvenement = e.target.hash.substring(1);
			let typeBtn = e.target.attributes;
			modal.ajaxModal(false, false, null, null);
		});

		$('[data-open-modal="conseiller"]').on('click', function (e) {
			let codeAdvisor = e.target.hash.substring(1);
			modal.ajaxModal(false, true, codeAdvisor, null);
		});

		// $('[data-event-id]').on('click', function (e) {
		// 	//sessionStorage.setItem('hashContact', window.location.hash);
		// 	let codeEvenement = e.target.hash.substring(1);
		// 	let typeBtn = e.target.attributes;
		// 	modal.ajaxModal(true, false, codeEvenement, typeBtn['data-evenement'].nodeValue);
		// });

		$('[data-open-modal="presse"]').on('click', function (e) {
			if (settingsApp.app !== 'TCA') {
				document.getElementById('libelleContactPresse').innerText = e.target.dataset.libelleContact;
				document.getElementById('contactPress').value = e.target.dataset.email;
			}
			modal.openModal(e);
		});

		$('.values__link').on('click', function (e) {
			modal.openModal(e);
		});

		$(document).on('click', ".modal__close", this.closeModal);

		if (typeof agenceConfig != "undefined") {
			if (window.location.hash != '' && agenceConfig.ville != '') {
				modal.hasHash(window.location.hash);
			}
		}
	},

	openModal: function (e) {
		e.preventDefault();

		var dataModal = e.target.dataset.openModal;

		$('.modal[data-modal="' + dataModal + '"]').addClass('modal_open');

		$('html').css('overflow', 'hidden');
	},

	closeModal: function (e) {
		e.preventDefault();

		if (sessionStorage.getItem('hashContact') != '#null') {
			history.pushState(null, null, sessionStorage.getItem('hashContact'));
		} else {
			history.pushState(null, null, ' ');
		}

		$(this).closest('.modal').removeClass('modal_open');

		$('html').css('overflow', 'auto');
	},

	ajaxModal: function (isEvent, isConseiller, id, type) {


		document.body.classList.add('loading');

		let idContainer = '';
		let url = '';
		let data = {}

		if (isConseiller) {
			idContainer = 'modalConseiller';
			data = {
				codeVendeur: id
			}
			url = agenceConfig.conseiller.url;

			// Si le hash ne correspond pas à un code conseiller
			// return false
			let arrConseiller = agenceConfig.conseiller.listeConseiller.split(',');
			if (!arrConseiller.includes(id)) {
				return false;
			}
		} else {
			idContainer = 'modalContact';
			url = agenceConfig.contact.url;
		}

		sessionStorage.setItem('hashContact', '#' + id);

		$.ajax({
			url: url,
			type: 'POST',
			data: data,
			success: function (data) {
				document.getElementById(idContainer).innerHTML = data;
				$('html').css('overflow', 'hidden');
				if (!isEvent && !isConseiller) {
					$('#formContact').parsley();
					$('#formContact').on('focusout', "#CodePostal", form.afficheVille);
					$('[data-modal="contact"]').addClass('modal_open');

					form.formRemoveError();
				}
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.error(jqXHR, textStatus, errorThrown);
			},
			complete: function () {
				document.body.classList.remove('loading');
			}
		});
	},

	hasHash: function (el) {
		let hash = el.substring(1);
		if (hash != '') {
			if (Number.isInteger(parseInt(hash))) {
				if(document.location.search === '') {
					history.pushState({ page: 1 }, "title 1", `${document.location.search}?evenement=${hash}`);
				} else {
					history.pushState({ page: 1 }, "title 1", `${document.location.search}&evenement=${hash}`);
				}

			} else {
				modal.ajaxModal(false, true, hash);
			}
		}
	},

	init: function () {
		modal.event();
	}
};