var content = {

	event : function() {
        $(document).on('click', "[data-show-content]", this.triggerShowContent);
    },

	triggerShowContent : function(e) {
        e.preventDefault();
        
        var dataContent = $(this).data('show-content');
        $('[data-content="' + dataContent + '"]').toggle();

    },

	init : function(){
        content.event();
	}
};
