var navigationTab = {

	event : function() {
        blockNavigationTab.on('click', "[data-show-tab]", this.navigationShowTab);
        blockNavigationTab.on('click', ".navigation-tab__close", this.navigationHideModal);
        $('.navigation-tab__list_fixed').on('click', ".navigation-tab__link", this.navigationShowModal);
    },

	navigationShowTab : function(e) {
        e.preventDefault();

        var navigationTab =  $(this);
        var dataTab = navigationTab.data("show-tab");

        $('.navigation-tab__item').removeClass('navigation-tab__item_active');
        navigationTab.addClass('navigation-tab__item_active');

        if(dataTab == 'formContactAgency') {
            $("[data-tab]").hide();
        }
        $("[data-tab='" + dataTab + "']").show();

    },

    navigationShowModal : function(e) {
        e.preventDefault();

        blockNavigationTab.addClass('is-active');
    },

    navigationHideModal : function(e) {
        e.preventDefault();

        blockNavigationTab.removeClass('is-active');
    },

	init : function(){
        navigationTab.event();
	}
};
