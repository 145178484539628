var map = {

	event: function () {
		$('#map').on('click', ".ville", this.showCardAgence);
	},

	setInfosCardAgence: function (a, b) {
		$('#villeAgence').text(a.libelle);
		$('#city-title').text(a.libelle);
		$('#adresseAgence').html(a.adresse);
		$('#linkAgence').attr('href', a.url);

		if (a.tel != undefined) {
			$('#telAgence').text(a.tel);
		} else {
			$('#telAgence').text(dataAgencies.default.tel);
		}
	},

	showCardAgence: function (a) {

		var villeAgenceNom = '';
		if (typeof isbelge === 'undefined') {
			var isbelge = false;
		}

		var a = $('<a>', {
			href: window.location
		});
		var hash = a.prop('hash');

		if (hash != '#evenements') {
			if (hash != '') {
				if (dataAgencies[hash.substring(1)] != undefined) {
					villeAgenceNom = hash.substring(1);
				} else {
					villeAgenceNom = 'default';
				}
			} else if (isbelge) {
				villeAgenceNom = 'bruxelles';
			} else {
				villeAgenceNom = 'default';
			}
		} else {
			villeAgenceNom = 'default';
		}

		map.setInfosCardAgence(dataAgencies[villeAgenceNom], villeAgenceNom);
	},

	init: function () {
		map.event();

		// INITIALIZE MAP
		$('#map').load('/Content/img/esprit-terdav/pages/informations/map.svg', function (response, status, xhr) {
			var villeSvg = $('#map').find('.ville');

			map.showCardAgence(villeSvg);

			if ($('body').is('.touch')) {
				villeSvg.on('click', function () {
					$('html, body').animate({
						scrollTop: $("#agenceAdresse").offset().top
					}, 2000);
				});
			}

			var carte = document.getElementById('map');
			var ville = carte.getElementsByClassName('ville');

			[].forEach.call(ville, function (el, index, array) {

				el.addEventListener('click', function () {
					[].forEach.call(array, function (s) {
						s.classList.remove('is-active');

					});
					el.classList.add('is-active');
					let slug = el.getAttribute('data-slug');
					window.location.hash = slug;
					// document.getElementById('city-select').value = dataAgencies[slug].id;
					// var index = document.getElementById('city-select').selectedIndex;
					$('#city-select').prop('selectedIndex', index).selectric('refresh');
					$('#refAgence').prop('selectedIndex', index).selectric('refresh');

				});
			});

			var a = $('<a>', {
				href: window.location
			});
			var hash = a.prop('hash');

			if (hash != '' && hash != '#evenements' && !Number.isInteger(hash.substring(1))) {
				let id = dataAgencies.default.id;
				if (dataAgencies[hash.substring(1)] != undefined) {
					id = dataAgencies[hash.substring(1)].id
				}

				document.getElementById('ville-' + id).classList.add('is-active');
				map.showCardAgence(hash.substring(1))
			} else {
				document.getElementById('ville-' + dataAgencies.default.id).classList.add('is-active');
			}
		});

	}
};