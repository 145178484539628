var navigationPanel = {

	event : function() {
        $('.navigation-panel').on('click', ".navigation-panel__link", this.navigationActive);
    },

    scrollActive : function() {
        var mainNavLinks = document.querySelectorAll(".navigation-panel .navigation-panel__item .navigation-panel__link");
		var fromTop = window.scrollY;

		mainNavLinks.forEach(link => {
			var section = document.querySelector(link.hash);

			if (section.offsetTop + 650 <= fromTop && section.offsetTop + section.offsetHeight + 650 > fromTop) {
				link.classList.add("navigation-panel__link_active");
			} else {
				link.classList.remove("navigation-panel__link_active");
			}
		});
    },

	navigationActive : function() {

        $('.navigation-panel .navigation-panel__item').removeClass('navigation-panel__item_active');
        $(this).closest('.navigation-panel__item').addClass('navigation-panel__item_active');

    },

	init : function(){
        navigationPanel.event();
	}
};
